<
<template>
  <div class="video">
    <IconPlay class="video-icon" @click.native="openFullscreen(key)" />
    <video
      ref="video"
      class="video-video"
      :controls="false"
      playsinline
      @click="openFullscreen(key)"
      poster="@/assets/video/GrottaHouse_Web_poster.jpg"
    >
      <source src="@/assets/video/GrottaHouse_Web.mp4" type="video/mp4" />
    </video>
    <span class="video-title">Interview</span>
  </div>
</template>
<script>
import IconPlay from "@/components/icons/IconPlay.vue";
import IconClose from "@/components/icons/IconClose.vue";

export default {
  components: { IconClose, IconPlay },
  props: {
    video: {
      type: Object,
    },
  },
  mounted() {
    document.addEventListener("fullscreenchange", this.closeFullscreen);
  },
  destroyed() {
    document.removeEventListener("fullscreenchange", this.closeFullscreen);
  },
  methods: {
    openFullscreen() {
      let video = this.$refs.video;

      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.webkitEnterFullscreen) {
        /* Safari */
        video.webkitEnterFullscreen();
      } else if (video.msRequestFullscreen) {
        /* IE11 */
        video.msRequestFullscreen();
      }

      this.$refs.video.play();
    },

    closeFullscreen() {
      if (!document.fullscreenElement) {
        let video = this.$refs.video;
        video.pause();
        video.currentTime = 0;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.video {
  display: flex;
  flex-direction: column;
  position: relative;

  &-icon {
    position: absolute;
    height: 9rem;
    width: 9rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    transition: all 0.5s;
    cursor: pointer;

    &:hover {
      transition: all 0.5s;
      height: 10.5rem;
      width: 10.5rem;
    }
  }

  &-video {
    height: 28vw;
    width: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    @media screen and (max-width: $md) {
      height: 40vw;
    }
    @media screen and (max-width: $xs) {
      height: 90vw;
    }
  }

  &-title {
    font-family: $fontBodoni;
    font-size: 2.4rem;
    line-height: 1.5;
    color: $white;
    margin-top: 8px;
    @media screen and (max-width: $xs) {
      margin-top: 14px;
    }
  }
}
</style>
