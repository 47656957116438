<template>
  <div class="videos">
    <div class="videos__header">
      <h1 class="videos__header_title" align="center">Videos</h1>
    </div>

    <div class="videos__body">
      <Video />
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Video from "@/components/Video.vue";

export default {
  components: { Video },

  data() {
    return {};
  },

  head: {
    title: function () {
      return {
        inner: "GH - Videos",
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },

  computed: {},

  async created() {
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Library",
        to: "/library",
      },
      {
        title: "Videos",
        to: "/library/videos",
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },

  methods: {
    ...mapMutations(["setBreadcrumbs"]),
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
}

.videos {
  min-height: 100vh;
  min-width: 100vw;
  background-color: $lightBlack;
  margin-top: 156px;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_title {
      font-size: 62px;
      line-height: 1.1;
      font-family: $fontBodoni;
      @media only screen and (max-width: $xs) {
        font-size: 39px;
        line-height: 1.28;
      }
    }
  }

  &__body {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 5rem;
    padding: 15.2rem 10.5rem 7.4rem;
    @media screen and (max-width: $md) {
      grid-template-columns: 1fr 1fr;
      padding: 8rem 8rem 7.4rem;
    }
    @media screen and (max-width: $sm) {
      padding: 8rem 3rem 7.4rem;
    }
    @media screen and (max-width: $xs) {
      grid-template-columns: 1fr;
      grid-column-gap: 25px;
      grid-row-gap: 25px;
      padding: 8rem 25px 24rem;
    }
  }
}
</style>
